
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Lato", sans-serif;
}

@font-face {
  font-family: 'AnandaNamaste';
  src: url('./assets/fonts/Ananda-Namaste-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RonySiswadi Architect 6';
  src: url('./assets/fonts/RonysiswadiArchitect6-X3V9G.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aspire';
  src: url('./assets/fonts/AspireDemibold-YaaO.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
    background: rgb(12, 12, 12);
    color: white;
}

.malhaar-body {
  color: #3a3a3a;
  /* color: #f5e5cd; */
    z-index: -10;
}

.malhaar-bg-img {
  background-image: url(/public/games/website/Malhaar/BG.png);
  opacity: 1;
  width: 100%;
}

.malhaar {
  /* font-family: 'RonySiswadi Architect 6', sans-serif; */
  font-family: 'AnandaNamaste', sans-serif;
}

.malhaar-text {
  font-family: 'Aspire', sans-serif;
  font-size: 4.1vh !important;
  /* filter: drop-shadow(1px 0.1rem 1px #462916); */
}

.feature-grid {
  display: flex;
  margin: 20px 0px;
  gap: 10vw;
}

.feature-desc {
  font-family: 'Aspire', sans-serif;
  font-size: 4vh !important;
  line-height: 5vh;
  letter-spacing: 2px;
  /* filter: drop-shadow(1px 0.1rem 1px #462916); */
}

.feature-title {
  font-family: 'AnandaNamaste', sans-serif;
  font-size: 5vh;
  filter: drop-shadow(2px 0.1rem 2px #462916d0);
  background: -webkit-linear-gradient(#ee5f41 10%, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent
}

.malhaar-title {
  font-size: 7.5vh;
  letter-spacing: 2px;
  margin-top: 15vh;
  color: #f5e5cd;
  filter: drop-shadow(5px 0.5rem 5px #462916);
}

.mech-img {
  width: 25vw;
  /* height: 50vh; */
  /* filter: drop-shadow(10px 0.1rem 10px #462916d0); */
  /* aspect-ratio: 16/9; */
  /* border: black 0.1em solid; */
}

@media only screen and (max-width: 600px) {
  /* .mech-img {
    display: none;
  } */

  .feature-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .off-white {
    color: #f5e5cd;
  }
}


.info {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.space-top {
  margin-top: 10vh;
}

.soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  font-size: 3.5vh;
}

.subtitle {
  font-size: 1.5vh;
  letter-spacing: 2px;
  line-height: 2em;
}

.title {
  font-size: 1.2vh;
  letter-spacing: 2px;
  margin-top: 15vh;
}

.ananda-title {
  font-family: 'AnandaNamaste';
  font-size: 1.2vh;
  letter-spacing: 2px;
  margin-top: 15vh;
}

h2 {
  position: relative;
  color: #cc0066;
  font-size: 3em;
  letter-spacing: 15px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0003);
  line-height: 0.70em;
  outline: none;
}

.malhaar-h2 {
  position: relative;
  color: #f5e5cd;
  font-size: 3em;
  letter-spacing: 15px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  /* -webkit-box-reflect: below 1px linear-gradient(transparent, #0003); */
  line-height: 0.70em;
  outline: none;
}

.game-head {
  font-size: 10vw;
}

.game-head2 {
  font-size: 7vw;
}

.flicker {
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%, 18%, 20%, 50.1%, 60%, 65.15%, 80%, 90.1%, 92%
  {
    color: #cc0066;
    text-shadow: 0 0 10px #cc0066,
                0 0 20 #cc0066,
                0 0 40px #cc0066,
                0 0 80px #cc0066,
                0 0 160px #cc0066;
  }

  18.1%, 20.1%, 30%, 50%, 60.1%, 65%, 80.1%, 90%, 92.1%, 100% 
  {
    color: #fff;
    text-shadow: 0 0 10px #cc0066,
                0 0 20 #cc0066,
                0 0 40px #cc0066,
                0 0 80px #cc0066,
                0 0 160px #cc0066;
  }
}

.malhaar-flicker {
  animation: malhaar-animate 5s linear infinite;
}

@keyframes malhaar-animate {
  0%, 18%, 20%, 50.1%, 60%, 65.15%, 80%, 90.1%, 92%
  {
    color: #934a4a;
    text-shadow: 0 0 10px #934a4a,
                0 0 20 #934a4a,
                0 0 40px #934a4a,
                0 0 80px #934a4a,
                0 0 160px #934a4a;
  }

  18.1%, 20.1%, 30%, 50%, 60.1%, 65%, 80.1%, 90%, 92.1%, 100% 
  {
    color: #fff;
    text-shadow: 0 0 10px #934a4a,
                0 0 20 #934a4a,
                0 0 40px #934a4a,
                0 0 80px #934a4a,
                0 0 160px #934a4a;
  }
}

.nowhere-flicker {
  animation: nowhere-animate 5s linear infinite;
}

@keyframes nowhere-animate {
  0%, 18%, 20%, 50.1%, 60%, 65.15%, 80%, 90.1%, 92%
  {
    color: #0e1010;
    text-shadow: 0 0 10px #0e1010,
                0 0 20 #0e1010,
                0 0 40px #0e1010,
                0 0 80px #0e1010,
                0 0 160px #0e1010;
  }

  18.1%, 20.1%, 30%, 50%, 60.1%, 65%, 80.1%, 90%, 92.1%, 100% 
  {
    color: #fff;
    text-shadow: 0 0 10px #0e1010,
                0 0 20 #0e1010,
                0 0 40px #0e1010,
                0 0 80px #0e1010,
                0 0 160px #0e1010;
  }
}

.logo-title { 
  /* position: relative; */
  color: #cc0066;
  font-size: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 100%;
  outline: none;
}

.menu { 
  /* position: relative; */
  float: right;
  font-size: 0.80em;
  color: white;
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 115%;
  outline: none;
}

.yellow {
  color: #f6f600;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: transparent;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0px 10px;
  height: 50px;
  font-size: 1em;
  letter-spacing: 3px;
  text-transform: uppercase;
  width: 100%;
  outline: none;
}

.navbar-right {
  float: right;
  color: white;
}

.grid {
  display: flex;
  margin: 20px 0px;
  gap: 20px;
}

.team-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
  gap: 20px;
}

.pattern{
  display: flex;
    /* margin: 50px 0px; */
    margin-top: 15vh;
    margin-bottom: 5vh;
    gap: 10px;
}

.lighter {
  font-weight: lighter;
}

.focus {
  color: #CC0066;
}

.space-sides {
  margin-left: 10vw;
  margin-right: 10vw;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 2em;
}

.para {
  font-size: 2.2vh;
  letter-spacing: 2px;
  line-height: 2em;
  margin-top: 5vh;
}

.letter-space {
  letter-spacing: 2px;
}

.no-decor {
  text-decoration: none;
}

.highlight {
  color: #CC0066;
}

.single-box {
  /* width: calc(100% / 3); */
  min-height: 400px;
  max-height: 50vw;
  height: fit-content;
  margin: 0px 24px 0px;
  text-decoration: none;
  margin-bottom: 50px;
}

.stuff-image img {
  padding: 10px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  min-width: 250px;
  width: 25vw;
  aspect-ratio: 4/5;
}

.details {
  display: block;
  margin-bottom: 50px;
  margin-top: 20px;
}

.white {
  color: white;
}

.line {
  border-top: 1px solid;
    /* border-left: 1px solid; */
    height: 0vw;
    width: 80px;
}

.malhaar-line {
  border-top: 3px solid;
    /* border-left: 1px solid; */
    height: 0vw;
    width: 100%;
    color: #f7e4cc;
    /* filter: drop-shadow(15px 15px #462916); */
}

.malhaar-line-pos {
  position: absolute;
  bottom: 0%;
  /* right: 40%; */
  z-index: 1;
}

.underline {
  border-bottom: 1px solid;
    height: 0vw;
    width: 80px;
}

.games-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* max-width: 1440px; */
  margin-top: 50px;
  /* margin-bottom: 100px; */
}

.games-list-tm .single-box {
  margin-top: 50px;
}

.extra-small {
  font-size: 1.4vh;
}

.grey {
  color: grey;
}

.ts {
  transition: all .2s ease-in-out;
}

.ts:hover {
  transform: scale(1.05);
}

.ts-yel {
  transition: all .2s ease-in-out;
}

.ts-yel:hover {
  transform: scale(1.05);
  color: #f6f600;
}

.ss {
  transition: all .2s ease-in-out;
}

.ss:hover {
  transform: scale(3.05);
}

.screenshots {
  margin-top: 50px;
  width: 100%;
  height: 100%;
  padding-top: 0vh;
}

.space50 {
  margin-top: 50px;
}

.ss-img {
  width: 200px;
  aspect-ratio: 16/9;
  border: black 0.1em solid;
}



.temple-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.journal-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
  transition: all .2s ease;
  /* filter: drop-shadow(16px 0.5rem 16px black); */
}

.journal-img:hover {
  /* transform: rotate(-2deg); */
}

.ss-img-logo {
  width: 150px;
  /* aspect-ratio: 16/9; */
}

.local-pic-malhaar {
  width: 150px;
  aspect-ratio: 1/1;
  border: #f2e4c5 0.1em solid;
}

.ss-img-nowhere {
  width: 250px;
  aspect-ratio: 16/9;
  border: black 0.1em solid;
}

.trailer {
  margin-top: 50px;
  width: 50%;
  height: 50%;
  padding-top: 0vh;
}

.malhaar-trailer {
  margin-top: 50px;
  width: 50%;
  height: 50%;
  padding-top: 0vh;
  filter: drop-shadow(10px 0.1rem 10px #462916d0);
}

.video {
  width: 100%;
  height: 100%;
  padding-top: 0vh;
  opacity: 0.4;
}

.bg-malhaar {
  width: 100vw;
  height: 93vh;
  padding-top: 0vh;
  opacity: 1;
  /* background-color: #0e1010; */
}

.malhaar-video {
  width: 100%;
  height: 100%;
  padding-top: 0vh;
  /* padding-bottom: 0.5vh; */
  opacity: 1;
  /* aspect-ratio: 16/9; */
  /* box-shadow: 0px -10px 10px 10px rgba(0, 0, 0, 0.6); */
/* background-color: rgb(12, 12, 12); */
  /* filter: drop-shadow(5px 1rem 5px rgb(95, 57, 13)) drop-shadow(5px -5px rgb(95, 57, 13)) drop-shadow(-5px 1rem 5px rgb(95, 57, 13)) ; */

}

.video-canvas {
  position: relative;
  z-index: -10;
}

.malhaar-video-canvas {
  position: relative;
  z-index: -10;
}

.malhaar-video-BG {
  position: relative;
  z-index: -10;
  background-color: #f7e4cb;
}

.text-over-video {
  position: absolute;
  top: 60%;
  right: 40%;
  z-index: 1;
  max-width: 58%;
}

.video-over-video {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 1;
  /* max-width: 100%; */
}

.game-page-details {
  position: relative;
}

.input {
  background: transparent;
  border: 2px solid;
  border-radius: 2px;
  border-color: #444;
  padding: 10px;
  width: 50vw;
}

.btn-normal {
  width: fit-content;
  height: 40px;
  border-radius: 5px;
  background-color: #CC0066;
  border-color: transparent;
  color: white;
  padding: 5px 10px;
  margin: 10px 0px;
  cursor: pointer;
}

.btn-normal-nowhere {
  width: fit-content;
  height: 40px;
  border-radius: 5px;
  background-color: #044151;
  border-color: transparent;
  color: white;
  padding: 5px 10px;
  margin: 10px 0px;
  cursor: pointer;
}

.play-frame {
  width: 60%;
  height: 60%;
  /* aspect-ratio: 16/9; */
}

.pointer {
  cursor: pointer;
}

.game-about {
  font-size: 1.7vh;
  letter-spacing: 2px;
  line-height: 2em;
  margin-left: 20vw;
  margin-right: 20vw;
}

.malhaar-about {
  font-size: 2.2vh;
  /* font-weight: bold; */
  letter-spacing: 2px;
  line-height: 2em;
  margin-left: 20vw;
  margin-right: 20vw;
}

.about-ind {
  font-size: 2.2vh;
  letter-spacing: 10px;
  line-height: 5em;
  margin-left: 20vw;
  margin-right: 20vw;
  margin-top: 10vw;
}

.malhaar-tagline {
  font-size: 2vh;
  letter-spacing: 10px;
  /* line-height: 5em; */
  margin-left: 40vw;
  margin-right: 20vw;
  color: #0e1010;
}

.buy-us-a-pixel {
  font-size: 2.8vh;
  font-weight: 400;
}